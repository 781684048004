import { ComponentProps, FC } from 'react';
import { VideoObject, WithContext } from 'schema-dts';

import { JsonLD } from 'src/general/components/JsonLD/JsonLD';

import { InlineVideo } from './InlineVideo';

interface Props extends Pick<ComponentProps<typeof InlineVideo>, 'sectionData'> {}

export const InlineVideoJsonLD: FC<Props> = ({ sectionData: { youtubeVideo, subTitle, title, videoDescription } }) => {
  const jsonLD: WithContext<VideoObject> = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: title,
    description: subTitle,
    video: {
      '@type': 'VideoObject',
      name: title,
      description: videoDescription ?? subTitle,
      thumbnailUrl: youtubeVideo.thumbnailImageUrl ?? videoDescription ?? youtubeVideo.videoUrl,
      width: {
        '@type': 'QuantitativeValue',
        value: youtubeVideo.width,
      },
      height: {
        '@type': 'QuantitativeValue',
        value: youtubeVideo.height,
      },
      uploadDate: 'N/A', // just a placeholder
    },
  };
  return <JsonLD jsonLD={jsonLD} />;
};
